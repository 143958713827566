.notFound {
    display: inline-block;
    margin: 15px;
}

.wrapper {
  position: relative;
}

.saveButton {
  position: absolute;
  top: 8px;
  left: 10px;
}