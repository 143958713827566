.container {
  display: flex;
  flex-direction: column;

}

.compensator {
  margin-top: 11px;
  margin-bottom: 11px;
}

.key {
  

}

.livetime {

}