



.no-wrap-text {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   padding-left: 1px;
   padding-right: 1px;
   /* max-width: 120px; */
}

.fix-table-cell {
  table-layout: fixed;
}

.noPadding {
  padding: 0;
}