.tableCell {
  padding: 6px 1px 6px 1px;
}

.tableSortLabel {
  margin-left: 18px;
}
.wrapper {
  position: relative;
}

.saveButton {
  position: absolute;
  top: 8px;
  left: 10px;
}

.admin {
  background-color: rgba(255, 224, 223, 0.436);
}